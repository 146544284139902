import React  from 'react';
import styled from 'styled-components';
import { InfoScreen } from './components/InfoScreen';

const Wrapper = styled.div`
  background-size: cover;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  white-space: pre-line;
`;

function App() {
  return (
        <Wrapper>
           <InfoScreen />
        </Wrapper>
  )
}

export default App;
