import styled from 'styled-components';
import background from '../assets/images/qrBg.svg';
import backgroundM from '../assets/images/bgMobile.svg';
import table from '../assets/images/table.svg';
import { Title } from './shared/Title';
import { Text } from './shared/Text';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 29vh 80px 2vh;
  color: #F5D3A6;
  text-align: left;
  background: url(${background}) no-repeat 0 0;
  background-size: cover;

  @media screen and (max-height: 695px) and (min-width: 1000px) {
    padding-top: 14vh;
    background-size: contain;
    background-position: center;
  }

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    padding: 20vh 0;
    background: url(${backgroundM}) no-repeat center 0;
  }
`;

const TitleStyled = styled(Title)`
  font-size: 65px;

  @media screen and (max-height: 700px) {
    font-size: 55px;
  }

  @media screen and (max-height: 550px) {
    font-size: 40px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 45px;
    text-align: center;
    max-width: 375px;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 320px) {
    font-size: 35px;
  }
`;

const TextStyled = styled(Text)`
  font-size: 25px;
  margin: 30px 0;

  @media screen and (max-width: 1000px) {
    text-align: center;
    white-space: initial;
    max-width: 275px;
    margin: 30px auto;
  }

  @media screen and (max-width: 320px) {
    font-size: 20px;
    max-width: 250px;
  }
`;

const Image = styled.div`
  background: url(${table}) no-repeat 0 100%;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70%;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    background-position: calc(100% + 100px) 100%;
  }
`;

const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const InfoScreen = () => {
    return (
        <Wrapper>
            <Content>
                <TitleStyled>
                    {'Регистрация\nна мероприятие окончена'}
                </TitleStyled>
                <TextStyled>
                    Следите за нашими событиями {'\n'}в <Underline
                    onClick={() => window.open('https://t.me/employerbrandexperts')}>канале</Underline>
                </TextStyled>
            </Content>
            <Image/>
        </Wrapper>
    );
};
